/** @jsx jsx */
import { jsx } from "theme-ui"

import ChapterCard from "./chapter-card"

export default function BlogListHome(props) {
  const data = props.data
  const chapters = data.edges
    .map(edge => <ChapterCard key={edge.node.id} data={edge.node} />)
  return <ChapterMaker data={chapters} />
}

const ChapterMaker = ({ data }) => (
  <section className="home-posts">
    <h2>
    <strong>Содержание</strong>{" "}
    </h2>
    <div className="grids col-1 sm-2 lg-3">{data}</div>
  </section>
)
